<template>
	<lee-user-main-page type="dark">
		<lee-user-page class="padding-bottom-100">
			<!--            <div class="padding-top-bottom 16 dark-2">-->
			<!--                VIP到期时间：<b class="green">{{vip_end_time ? vip_end_time : '还不是VIP'+vip_end_time}}</b>-->
			<!--            </div>-->
			<div class="padding-top-bottom 16 dark-2" v-for="(item,index) in myVipList">
				<el-row :gutter="20">
					<el-col :span="8">
						{{ item.title }}到期时间：<b class="green">{{ item.vip ? $moment.unix(item.vip.vip_end_time).format('YYYY-MM-DD') : '还不是VIP' }}</b>
					</el-col>
					<el-col :span="8">
						浏览剩余次数：<b class="green">{{parseInt(item.view.total - item.view.now)}}</b> / {{item.view.total}}（天）
					</el-col>
					<el-col :span="8">
						文件下载剩余次数：<b class="green">{{parseInt(item.down.total - item.down.now)}}</b> / {{item.down.total}}（天）
					</el-col>
				</el-row>
			</div>
			<template v-if="companyJoined === 1">
				<div style="background-color: #f0f9eb;border:1px solid #e1f3d8;padding:20px;line-height: 30px;" class="f16">
					<div>您是企业用户，无法单独购买VIP</div>
					<div class="orange f-b">如需购买VIP，请联系企业管理员</div>
				</div>
			</template>
			<template v-else>
				<div class="margin-top-20" style="background-color: #f6f6f6;padding:20px;">
					<div class="padding-top-bottom text-center f20 f-lq">
						续期或购买VIP
					</div>
<!--					<el-row :gutter="20">-->
<!--						<el-col :span="6" v-for="(item,index) in list">-->
<!--							<div :class="active === item.id ? 'bg-green light' : 'bg-gray-3 dark-1'" class="f20 border-radius-10 text-center hand hide margin-bottom" @click="changeActive(item)" style="height: 50px;line-height: 50px;">-->
<!--								<div>{{ item.title }}</div>-->
<!--								&lt;!&ndash;                            <div class="f30 margin-top" :class="active === item.id ? 'light' : 'green'">￥{{item.price}}</div>&ndash;&gt;-->
<!--								&lt;!&ndash;                            <div class="text-line-through f-n f18" :class="active === item.id ? 'gray-2' : 'dark-3'" style="height: 25px;">{{parseFloat(item.old_price) > 0 ? '￥'+item.old_price : ''}}</div>&ndash;&gt;-->
<!--							</div>-->
<!--						</el-col>-->
<!--					</el-row>-->
<!--					<div class="padding-20 bg-green-light" v-if="buyInfo">-->
<!--						浏览次数：{{ buyInfo.view_count === 0 ? '无限' : buyInfo.view_count }}次 / 天<br/>-->
<!--						文件下载：{{ buyInfo.down_count === 0 ? '无限' : buyInfo.down_count }}次 / 天-->
<!--					</div>-->
					<el-row :gutter="20" class="margin-top">
						<el-col :span="8" v-for="(item,index) in price_list">
							<div :class="price_choose.id === item.id ? 'border-green bg-green-light green' : 'border-dark-4 bg-gray-3 dark-1'" class="f20 border-radius-10 text-center border-w-4 hand hide margin-bottom padding-top-bottom-40" @click="choosePrice(item)" style="height: 200px;">
								<div>{{ item.title }}</div>
								<div class="f30 margin-top" :class="price_choose.id === item.id ? 'green' : 'dark-2'">￥{{ item.price }}
								</div>
								<template v-if="item.price_diff > 0 && item.disabled === false">
									<div class="f14" v-for="(st,si) in item.price_diff_list">{{ st.vip.title }}本次抵扣{{ st.diff_price }}元
									</div>
								</template>
								<div class="text-line-through f-n f18" :class="price_choose.id === item.id ? 'dark-2' : 'dark-3'" style="height: 25px;">
									{{ parseFloat(item.old_price) > 0 ? '￥' + item.old_price : '' }}
								</div>
							</div>
						</el-col>
					</el-row>
					<!--                <div v-if="buyInfo && buyInfo.des" v-html="'说明：'+buyInfo.des"></div>-->
					<div class="text-center padding-top-bottom margin-top-50" v-if="price_choose">
						<el-button type="success" class="padding-left-50 padding-right-50 f20 f-n box-shadow-green" @click="getPayCode">
							{{ price_choose.price_diff > 0 ? '升级VIP' : '立即购买/续期VIP' }}(￥{{ price_choose.price }})
						</el-button>
					</div>
				</div>
			</template>
		</lee-user-page>
		<el-dialog
			title="支付"
			:visible.sync="showPay" @close="closePay">
			<lee-pay-view :name="buyInfo.title" :price="price_choose.price" title="购买VIP" sub-title="VIP金额" file-size="" :qrcode="'data:image/jpeg;base64,'+qrcode" v-if="buyInfo && price_choose"></lee-pay-view>
		</el-dialog>
	</lee-user-main-page>
</template>

<script>
export default {
	name: "Vip",
	data() {
		return {
			vip_state: 0,
			vip_end_time: '',
			list: [],
			active: '',
			showPay: false,
			time: null,
			qrcode: '',
			order_id: '',
			buyInfo: null,
			price_choose: null,
			myVipList: [],
			price_list: [],
			companyJoined:0
		}
	},
	mounted() {
		this.getDetail();
		// this.getList();
	},
	methods: {
		getDetail() {
			let _this = this;
			this.$http.post({
				url: '/AuthVipPrice/listAll',
				data: null,
				success(res) {
					_this.companyJoined = res.companyJoined;
					_this.myVipList = res.list;
					if(res.companyJoined === 0) {
						_this.getList();
					}
				}
			})
		},
		// getDetail(){
		//     let _this = this;
		//     this.$http.post({
		//         url:'/UserProfile/getBase',
		//         data:null,
		//         success(res){
		//             _this.vip_end_time = res.vip_end_time > 0 ? _this.$moment.unix(res.vip_end_time).format('YYYY-MM-DD') : '';
		//             _this.vip_state = res.vip_state;
		//         }
		//     })
		// },
		getList() {
			let _this = this;
			this.$http.post({
				url: '/VipPrice/listAll',
				data: {orderBy: 'sort', sortBy: 'asc', state: 1, pid: '0'},
				success(res) {
					let _list = [];
					let _has_list = [];

					if (res.length > 0) {
						for (let i = 0; i < res.length; i++) {
							// for (let j = 0; j < _this.myVipList.length; j++) {
							// 	if (res[i].id === _this.myVipList[j].id && (_this.myVipList[j].vip || _this.myVipList[j].is_super === 1)) {
									_list.push(res[i]);
								// }
							// }
						}
						// for (let i = 0; i < res.length; i++) {
						// 	for (let j = 0; j < _this.myVipList.length; j++) {
						// 		if (res[i].id === _this.myVipList[j].id && (_this.myVipList[j].vip || _this.myVipList[j].is_super === 1)) {
						// 			_list.push(res[i]);
						// 		}
						// 	}
						// }
						//
						// for (let j = 0; j < _this.myVipList.length; j++) {
						// 	if (_this.myVipList[j].vip) {
						// 		_has_list.push(_this.myVipList[j]);
						// 	}
						// }
						//
						// if (_list.length === 1 && _list[0].is_super === 1) {
						// 	if (_list.length !== _has_list.length) {
						// 		_list = res;
						// 	}
						// }

						_this.list = _list;

						_this.active = _list[0].id;
						_this.buyInfo = _list[0];

						_this.getPrice();
					}
				}
			})
		},
		getPrice() {
			let _this = this;
			this.$http.post({
				url: '/VipPrice/listAll',
				data: {orderBy: 'sort', sortBy: 'asc', state: 1, pid: this.active},
				success(res) {
					_this.price_list = res;

					if (res.length > 0) {
						for (let i = 0; i < res.length; i++) {
							if (res[i].disabled === false) {
								_this.price_choose = res[i];
								break;
							}
						}
						// _this.buyInfo = res[0];
					}
				}
			})
		},
		changeActive(item) {
			this.active = item.id;
			this.buyInfo = item;

			this.getPrice();
		},
		choosePrice(item) {
			if (item.disabled === false) {
				this.price_choose = item;
			} else {
				this.$message.error('当前价格无法选择，请选择更高的时长以升级VIP')
			}
		},
		getPayCode() {
			let _this = this;
			this.$http.post({
				url: '/Order/getPayCode',
				data: {id: this.price_choose.id, order_type: 'vip'},
				success(res) {
					_this.qrcode = res.qrcode;
					_this.order_id = res.order_id;
					_this.showPay = true;
					_this.timeDown('checkState');
				}
			})
		},
		timeDown(type) {
			if (type === 'checkState') {
				this.time = setInterval(this.checkState, 3000)
			}
		},
		closePay() {
			this.showPay = false;
			clearInterval(this.time);
			this.time = null;
			this.qrcode = '';
			this.order_id = '';
		},
		checkState() {
			let _this = this;
			this.$http.post({
				url: '/Common/checkOrder',
				data: {id: this.order_id},
				success(res) {
					if (res.state === 2) {
						clearInterval(_this.time);
						_this.time = null;
						// _this.info.bid_state = 1;
						_this.showPay = false;
						_this.$message.success('支付成功');

						_this.getDetail();
					}
				}
			})
		},
	},
	destroyed() {
		if (this.time) {
			clearInterval(this.time);
			this.time = null;
		}
	}
}
</script>
