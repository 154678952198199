<template>
	<lee-page :bg-img="$isMobile ? '/images/bg_head_9.jpg' : '/images/bg_head_3.jpg'" :title="$isMobile ? '' : '研究报告'" :sub-title="$isMobile ? '' : 'Research Reports'" des="" :search="!$isMobile" :bread="[{title:'研究报告',url:'/yanjiu'},{title:'搜索'}]" :back="$isMobile" :height="$isMobile ? '120' : '280'" show-year>
		<div :class="$isMobile ? 'padding-left-right bg-light' : 'bg-img-2  padding-top-50'" style="background:#f8f9fa">
			<div class="cl"></div>
			<div class="padding-bottom-100" v-if="list.length > 0">
				<div class="text-center dark-3 padding-bottom-30">共查询到 <b class="red">{{ total }}</b> 条相关内容</div>
				<div class="margin-auto" :style="{width: '1500px'}">
					<el-row :gutter="10">
						<el-col :md="8" :xs="24" v-for="(item,index) in list" class="margin-bottom">
							<div class="bg-light border-radius-5 hand" :style="{height:$isMobile ? '' : '225px'}" :class="$isMobile ? 'padding' : 'padding-20'" @click="$router.push({path:(item.type === 'domain' ? '/domain/detail/' : '/industry/detail/')+item.id})">
								<div class="cl"></div>
								<div class="border-bottom padding-bottom" style="height:60px;line-height: 20px;">
									<img src="/images/icon/icon_36.png" width="20" height="20" class="vm"/>
									<div class="f18 margin-left-30" style="height:50px;line-height: 20px;margin-top:-20px;" v-html="item.title"></div>
								</div>
								<el-row :gutter="10" class="margin-top">
									<el-col :md="24" :xs="12" class="margin-bottom" v-if="item.author">
										<img src="/images/icon/icon_37.png" width="20" height="20" class="margin-right"/>
										<span class="vm dark-3" :class="$isMobile ? 'f12' : 'f16'">{{ item.author }}</span>
									</el-col>
									<el-col :md="24" :xs="12" class="margin-bottom">
										<img src="/images/icon/icon_38.png" width="20" height="20" class="margin-right"/>
										<span class="vm dark-3" :class="$isMobile ? 'f12' : 'f16'" v-if="item.push_time">{{ item.push_time }}</span>
									</el-col>
									<el-col :md="24" :xs="12" class="margin-bottom" v-if="!$isMobile">
										<img src="/images/icon/icon_39.png" width="20" height="20" class="vm"/>
										<div class="margin-left-30 text-line-2 vm f16 dark-3" style="height: 40px;line-height: 20px;margin-top: -20px" v-html="item.content"></div>
									</el-col>
								</el-row>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="text-center margin-top-100" v-if="!$isMobile">
					<el-pagination
						background
						layout="pager, jumper"
						:page-size="pageSize"
						:current-page.sync="searchForm.page"
						:total="total"
						@current-change="pageChange">
					</el-pagination>
				</div>
			</div>
			<div class="text-center padding-top-bottom-40 f20 dark-3" v-else>
				未查询到相关内容
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "Index",
	data() {
		return {
			list: [],
			pageSize: 12,
			total: 0,
			searchForm: {
				page: 1,
				title: '',
				title_type: ''
			}
		}
	},
	created() {
		if (!this.$utils.isEmptyObject(this.$route.query)) {
			this.searchForm = {...this.$route.query};
		}

		this.searchForm.page = this.searchForm.page ? parseInt(this.searchForm.page) : 1;
	},
	mounted() {
		// if(this.keyword){
		//     this.toSearch();
		// }
		this.getList();
	},
	methods: {
		getList() {
			let _this = this;

			this.$http.post({
				url: '/Report/listPage',
				data: {...this.searchForm, pageSize: this.pageSize,orderBy:'push_time',sortBy:'desc'},
				success(res) {
					_this.list = res.list;
					_this.total = res.totalCount;
				}
			})
		},
		pageChange(page) {
			this.searchForm.page = page;

			this.$router.replace({query: {...this.searchForm}});
			// this.getList();
		},
		// onSearch(k){
		//     console.log(k);
		//     this.searchForm.title = k.title;
		//     this.searchForm.title_type = k.title_type;
		//
		//     this.$router.replace({query:{...this.searchForm}});
		// }
	}
}
</script>
